import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import HeroImage from "../images/partner-banner.png";   
import ShieldSecurityIcon from "../assets/icons/shield-security-icon.svg"; 
import AnalyticsIcon from "../assets/icons/analytics-icon.svg";   
import AiIcon from "../assets/icons/ai-icon.svg";    
import LeaderIcon from "../assets/icons/leader-gray-icon.svg";
import CopyIcon from "../assets/icons/copy-gray-icon.svg"; 
import FeaturesCmsIcon from "../assets/icons/features-cms-icon.svg";
import BookIcon from "../assets/icons/book-gray-icon.svg";
import PersonIcon from "../assets/icons/person-icon.svg";
import RevenueIcon from "../assets/icons/revenue-icon.svg";
import PeopleTransparentIcon from "../assets/icons/people-transparent-icon.svg";
import UserCirlceAddIcon from "../assets/icons/user-cirlce-add-icon.svg";
import MarketingIcon from "../assets/icons/marketing-gray-icon.svg";
import Select from 'react-select'

const PartnerPage = () => {
    const options = [
        { value: 'job1', label: 'Job1' },
        { value: 'job2', label: 'Job2' },
        { value: 'job3', label: 'Job3' }
    ]
    return (
        <div className="template-partner header-position header-white">
            <Layout>
                <div className="hero-banner mb-208">
                    <div className="container">  
                        <div className="hero-banner-wrap">
                            <div className="left-block">
                                <div className="banner-text"> 
                                    <h2>The Experro Partner Program</h2>
                                    <p className="mb-48">Join our partner program to help companies unleash the power of personalization with a leading digital experience platform while building long-term relationships and consistent revenue channel.</p>
                                    <Link className="button black-button right-arrow" to="#partner-with-us">Become a Partner</Link>
                                    <Link className="button button-secondary" to="/partner-login/">Access Partner Portal</Link>
                                </div>
                            </div>
                            <div className="right-block">
                                <img loading="lazy" src={HeroImage} title="Delivering Omnichannel Luxury Shopping Experience" alt="Delivering Omnichannel Luxury Shopping Experience" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="icon-with-text-block mb-208">
                    <div className="container">
                        <div className="section-title text-center mb-80">
                            <h2>Why partner with Experro?</h2>
                        </div>
                        <div className="custom-row d-flex row-3">
                            <div className="grid">
                                <div className="item-inner">
                                    <div className="icon-block">
                                        <AiIcon />
                                    </div>
                                    <div className="text-block">
                                        <h6>Next-gen Technology</h6>
                                        <p className="small-text">Represent the leading AI/ML-powered Digital Experience Platform made to grow personalized commerce.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="item-inner">
                                    <div className="icon-block">
                                        <ShieldSecurityIcon />
                                    </div>
                                    <div className="text-block">
                                        <h6>Enterprise Grade Security</h6>
                                        <p className="small-text">Benefit from co-marketing activities, sales support, and our industry knowledge to win new business together.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="item-inner">
                                    <div className="icon-block">
                                        <AnalyticsIcon />
                                    </div>
                                    <div className="text-block">
                                        <h6>Grow Your Business</h6>
                                        <p className="small-text">Build a profitable long-term relationship by becoming a certified solution provider and standing out from competitors.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             

                <div className="experro-more-details-section partner-benefits mb-208">
                    <div className="container">
                        <div className="section-title text-center mb-80">
                            <h2>Partner Benefits</h2>
                        </div>
                        <div className="icon-with-text-block icon-with-text-border-box">
                            <div className="custom-row d-flex flex-wrap row-3">
                                <div className="grid mb-32">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <RevenueIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Revenue Share</h6>
                                            <p className="small-text">Receive up to 10% ongoing revenue share of the customer’s monthly subscription fee.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid mb-32">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <MarketingIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Co-marketing</h6>
                                            <p className="small-text">Showcase case studies on the partner listing pages and leverage cross-promotional marketing.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid mb-32">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <PersonIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Free License</h6>
                                            <p className="small-text">Develop or migrate your agency’s website using Experro CMS with a lifetime free license.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid mb-32">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <PeopleTransparentIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Dedicated Team</h6>
                                            <p className="small-text">Dedicated account manager to help you tackle any question you or your client might have.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid mb-32">
                                    <div className="item-inner">
                                        <div className="icon-block"> 
                                            <LeaderIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Technology Perks</h6>
                                            <p className="small-text">Accelerate your business with exclusive offers, discounts, credit, and more with our tool partnerships.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid mb-32">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <UserCirlceAddIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Free Staff Members</h6>
                                            <p className="small-text">Add unlimited staff members at no cost that you can use on all development and customer spaces.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <BookIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Learning Center</h6>
                                            <p className="small-text">Access educational and sales promotional material for better, faster, and more efficient selling.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <FeaturesCmsIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Build Apps</h6>
                                            <p className="small-text">Build your custom apps, features, components, and more at a reasonable cost to use in all your spaces.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <CopyIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Development Spaces</h6>
                                            <p className="small-text">Unlimited and free development space for all your customers in one place.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="experro-business-cycle partner-business-cycle mb-208">
                    <div className="container">
                        <h3 className="font-medium line-height-60 mb-80">Partnership Types</h3>
                        <div className="custom-row d-flex row-3">
                            <div className="grid">
                                <Link to="#" className="business-cycle-item cms-block">
                                    <span>CMS</span>
                                    <span className="big-heading">Sourcing<br></br>Referrals</span>
                                </Link> 
                            </div>
                            <div className="grid">
                                <Link to="#" className="business-cycle-item aiml-block">
                                    <span>AI/ML</span>
                                    <span className="big-heading">Digital<br></br>Agencies</span>
                                </Link> 
                            </div>
                            <div className="grid">
                                <Link to="#" className="business-cycle-item cdp-block">
                                    <span>CDP</span>
                                    <span className="big-heading">Enterprise<br></br> Consultants</span>
                                </Link> 
                            </div> 
                        </div>
                    </div>
                </div>


                <div className="content-form-block mb-208" id="partner-with-us">
                    <div className="container-medium">
                        <div className="content-form-inner custom-row align-v-center row-2 d-flex">
                            <div className="content-block grid"> 
                                <h3 className="mb-48">4 Steps to Join Experro Partner Program</h3>
                                <ul>
                                    <li className="mb-32">
                                        <p className="font-medium"><b>1.</b>Apply to the program</p>
                                        <span className="font-light-color small-text">Apply to be a partner within minutes. We’ll respond within 24 hours.</span>
                                    </li>
                                    <li className="mb-32">
                                        <p className="font-medium"><b>2.</b>Showcase your company</p>
                                        <span className="font-light-color small-text">Provide your company’s logo and brief to appear in our partner directory.</span>
                                    </li>
                                    <li className="mb-32">
                                        <p className="font-medium"><b>3.</b>Activate benefits</p>
                                        <span className="font-light-color small-text">Start our partnership by completing the required training and certification to enjoy partner benefits.</span>
                                    </li>
                                    <li>
                                        <p className="font-medium"><b>4.</b>Grow with us</p>
                                        <span className="font-light-color small-text">Utilize the support of our partnership and grow your business with Experro.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="form-block grid">
                                <div className="form-inner">
                                    <h4 className="link-color">Partner With Us</h4>
                                    <p className="font-light-color small-text">Tell us which one and we’ll update you when it’s ready!</p>
                                
                                    <form>
                                        <div className="custom-row row-2 d-flex flex-wrap">
                                            <div className="grid mb-24">
                                                <input type="text" placeholder="First name*"></input>
                                            </div>
                                            <div className="grid mb-24">
                                                <input type="text" placeholder="Last name*"></input>
                                            </div>
                                            <div className="grid mb-24">
                                                <input type="text" placeholder="Company name*"></input>
                                            </div>
                                            <div className="grid mb-24">
                                                <input type="email" placeholder="Company email*"></input>
                                            </div>
                                            <div className="grid mb-24 full">
                                                <input type="text" placeholder="Phone number*"></input>
                                            </div>
                                            <div className="grid mb-24 full">
                                                <input type="text" placeholder="Job title*"></input>
                                            </div>
                                            <div className="grid full mb-24">
                                                <input type="text" placeholder="Website*"></input>
                                            </div>
                                            <div className="grid full mb-24">
                                                <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"></input>
                                                <label className="small-text" for="styled-checkbox-1">I’d like updates on Experro news, events and services (see <Link href="/privacy-policy/"> Privarcy policy</Link>).</label>
                                            </div>

                                            <div className="grid full">
                                                <input type="submit" className="button black-button" value="Get in Touch"></input>
                                            </div> 
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
            </Layout>
        </div>
        
    );
}

export default PartnerPage;